.bordered-box {
  border-style: solid;
  border-width: 1px;
  border-color: #91C154;
  background: #FFFFFF;
  margin-bottom: .5em;
  color: #5E5E5E;

  > table tr {
      border-width: 0 0 1px 0;
      border-style: solid;
      border-color: #DDD;
    }

  table {
    width: 100%;
    th {
      background-color: #F4F4F4;
      font-weight: bolder;
      padding: 1em;
    }

    td:nth-child(1){
      padding-left: .5em;
    }
  }

  .box-content {
    margin: .5em;
  }

  .table-action-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 2em;
    padding: .5em 0;
  }

  .dual-table td:nth-child(1) {
    color: #B2B2B2;
  }
}


.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 2em;
  padding: .5em 0;
}

.box-title {
  color: #FFFFFF;
  background-color: $tender-color;
  margin-bottom: 1em;
  padding: 1em;
  .bolder {
    font-weight: bolder;
    padding-right: 2em;
  }
  a {
    color: #FFFFFF;
  }
}

.box-subtitle {
  font-weight: bolder;
  margin: .5em;
}

.bordered-title {
  background-color: $tender-color;
}

.tender-text-color {
  color: $tender-color;
}

.cluster-text-color {
  color: $cluster-color;
}

.tender-background-color {
  background-color: $tender-color;
}

.cluster-background-color {
  background-color: $cluster-color;
}

.cluster-border-color {
  border-color: $cluster-color;
}

.bordered-content {
  padding: .5em;
}

.tender-title {
  color: #91C154;
}

.cluster-title {
  color: $cluster-color;
}

.tender-actions {
  justify-content: flex-end;
  display: flex;
  padding-right: .5em;
}

.bfs-show__header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  div, a {
    display: inline-block;
  }
}
