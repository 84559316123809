.bfs-header {
  &--wrapper {
    padding: 20px 15%;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-logo--wrapper {
    width: 20%;
    height: 75px;
    margin: 10px 0;
  }

  &-logo {
    display: block;
    background-image: url("../images/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
  }

  &__menu {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 11px;

    &__link {
      color: $bfs-dark-grey;
      font-weight: bolder;
      margin: 5px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &--active {
        @extend .bfs-header__menu__link;
        color: $bfs-blue;

        .bfs-icon--home {
          @extend .bfs-icon--home--active;
        }

        .bfs-icon--help {
          @extend .bfs-icon--help--active;
        }
      }
    }
  }

  &__switch {
    width: 15%;
  }

  &-link {
    display: block;
  }

  &__info {
    color: $bfs-grey;
    font-size: 10px;
    text-align: right;
  }
}

.bfs-switch {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 16px;

  &__link {
    color: $bfs-grey;
    margin: 5px;

    &--active {
      color: $bfs-blue;
      font-weight: bolder;
    }
  }
}
