.bfs-login {
  background-color: $bfs-light-grey;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-content {
    background-color: $bfs-light-grey;
    width: 50%;

    .bfs-logo-wrapper {
      width: 100%;
      height: 75px;
      margin-bottom: 30px;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 30px;
    width: 40%;
    margin: 0 auto;
    box-shadow: 0 0 20px rgba(0,0,0,0.5);

    form {
      width: 85%;
      margin: 30px 10px 20px 10px;
    }
  }

  &-footer {
    background-color: white;
    height:5vh;
    padding: 15px 0;
    font-size: 14px;

    a {
      color: $bfs-grey;
    }
  }

  &__input-wrapper {
    margin-bottom: 15px;
  }

  &__error-message {
    background-color: red;
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;
    color: $bfs-white;
    border-radius: 10px;
  }
}

.bfs-input-login-page {
  border-radius: 10px;
  background-color: $bfs-light-grey;
  border: none;
}

.bfs-btn {
  background-color: $bfs-blue;
  border: none;
  box-shadow: 0 0 20px rgba(0,151,216,0.5);
  border-radius: 40px;
  width: 70%;
  margin-top: 15px;
}

.bfs-registration-question-wrapper {
  text-align: center;
  margin: 20px auto 100px auto;
  font-size: 13px;
  .bfs-registration-question {
    margin-bottom: 10px;
  }

  p {
    margin: 0;
    color: $bfs-grey;
  }

  a {
    color: $bfs-dark-grey;
    font-weight: 700;
  }
}

.bfs-footer-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center
}
