.btn {
  border-radius: 0;
}

.btn-primary {
  background-color: $bfs-blue;
  border-color: $bfs-blue;
  &:hover{
    background-color: $primary;
    border-color: $primary;
  }
}

.form-control {
  border-radius: 0;
  background-color: $bfs-white;
}

.form-filter {
  border-radius: 0;
  background-color: $bfs-white;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-width: 6rem;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
