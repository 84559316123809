.bfs-table {
  display: flex;
  flex-direction: column;
  color: $bfs-grey;
  margin-bottom: 20px;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  overflow-x: auto;

  &__icon_container {
    display: flex;
    flex-direction: row-reverse;
    padding-right: .5em;
  }

  &__header_row {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: $bfs-light-grey;
    color: $bfs-dark-grey;
    font-weight: bolder;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background-color: $bfs-white;
    margin-bottom: 2px;
  }

  &__cell {
    padding: 10px 0;

    &--sm {
      flex-grow: 1;
      flex-basis: 10%;
    }

    &--md {
      flex-grow: 2;
      flex-basis: 13%;
    }

    &--bg {
      flex-grow: 3;
      flex-basis: 30%;
    }

  }

  &__link {
    text-decoration: underline;
    color: $bfs-dark-grey;
  }

  &__align-left {
    text-align: left;
    margin-left: 25px;
  }

  &__align-right {
    text-align: right;
    padding-right: 20px;
  }

  &__line-through {
    text-decoration: line-through;
  }
}
