.bfs-message-actions {
    float: right
}

.bfs-message-response {
    margin-left: 20px;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      border: 1px solid #737373;
      opacity: 0.5;
      top: 0;
      left: -18px; 
      bottom: 0;
    }
}