.bfs-page {
  margin: 15px 0;
  font-size: 14px;

  &__header {
    font-size: 14px;
    color: $bfs-blue;
    font-weight: bolder;
    margin: 15px 0;

    &--simple {
      @extend .bfs-page__header;
      margin: 0;
    }

    &--sub {
      @extend .bfs-page__header;
      color: $bfs-dark-grey;
    }
  }

  &__block {
    font-size: 14px;
    padding: 15px 25px;
    background-color: $bfs-white;
    color: $bfs-grey;
    margin-bottom: 10px;

    &-simple {
      @extend .bfs-page__block;
      margin-bottom: 0;
    }

    &-bordered {
      @extend .bfs-page__block;
      border: 1px $cluster-color solid;
      padding: 15px;

      .bfs-page__header {
        margin: 0 0 15px;
      }

      &-green {
        @extend .bfs-page__block-bordered;
        border: 1px $tender-color solid;

        .bfs-page__header {
          color: $bfs-grey;
        }
      }
    }

    &-full {
      @extend .bfs-page__block;
      background: $cluster-color;
      color: $bfs-white;
      padding: 15px;

      &-green {
        @extend .bfs-page__block-full;
        background: $tender-color;

        .bfs-link {
          color: $bfs-white;
          text-decoration: underline;
        }
      }
    }

    a {
      color: $bfs-grey;
      text-decoration: underline;
    }
  }

  .bfs-bolder {
    font-weight: bolder;
  }
}

.bfs-columns {
  display: flex;
  flex-direction: row;
  justify-content: center;

  div {
    width: 50%;
  }

  p {
    margin: 0;

  }
}

.bfs-company-contact {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &--sm {
    flex-basis: 15%;
    flex-grow: 1;
    color: #b5b5b5;
  }

  &--bg {
    flex-basis: 85%;
    flex-grow: 5;
  }
}

.bfs-light-grey {
  color: #b2b2b2;
}
