.bfs-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 14px;
  color: $bfs-grey;

  &__link {
    margin-bottom: 8px;
    color: $bfs-grey;
    margin-right: 10px;

    &::before {
      content: "> ";
    }

    &--active {
      color: $bfs-blue;
      font-weight: bolder;
    }
  }

  &__header {
    font-weight: bolder;
    color: $bfs-dark-grey;
    padding-bottom: 8px;
  }

  &__main-header {
    font-weight: bolder;
    color: $bfs-blue;
    padding-bottom: 8px;
  }

  &__block {
    margin-left: 15px;
    margin-bottom: 20px;
  }

  &--left, &--right {
    margin: 60px 0;
  }

  &__page {
    margin-bottom: 20px;

    .bfs-menu__link {
      margin-bottom: 0;
    }
  }
}
