.bfs-search-wrapper {
  p {
    margin: 10px 0;
    font-size: 14px;
    color: $bfs-dark-grey;
    font-weight: bold;
  }

  .bfs-search {

    &__block {
      display: flex;
      flex-direction: row;

      .bfs-search-input {
        border-radius: 10px;
        border: none;
        height: 30px;
        outline:none;
        padding: 5px;
      }

      .bfs-search-button {
        border-radius: 50%;
        padding: 9px;
        background-color: $bfs-blue;
        border: none;
        margin-left: 10px;
        outline: none;
      }

      .bfs-triangle_right {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 10px solid $bfs-white;
        border-bottom: 5px solid transparent;
        margin-left: 4px;
        border-radius: 1px;
      }
    }
  }
}