.bfs-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-repeat: no-repeat;

  &--show {
    background-image: url("../icons/Bekijken.svg");
  }

  &--edit {
    background-image: url("../icons/Wijzigen.svg");
  }

  &--add {
    background-image: url("../icons/Toevoegen.svg");
  }

  &--upgrade {
    background-image: url("../icons/Bedrijf_Upgraden.svg");
  }

  &--downgrade {
    background-image: url("../icons/Bedrijf_Downgraden.svg");
  }

  &--lopende-tenders {
    background-image: url("../icons/Lopende_Tenders.svg");
  }

  &--toewijzen-tenders {
    background-image: url("../icons/Tender_Toewijzen.svg");
  }

  &--password {
    background-image: url("../icons/Paswoord_Vernieuwen.svg");
  }

  &--toegewezen-tender {
    background-image: url("../icons/Toegewezen_Tender.svg");
  }

  &--tender-deelnemers {
    background-image: url("../icons/Tender_Deelnemers.svg");
  }

  &--toewijzen-tenders--active {
    background-image: url("../icons/Tender_Toewijzen.svg");
    width: 16px;
    height: 20px;
    margin-right: 5px;
    background-color: $bfs-yellow;
  }

  &--home {
    background-image: url("../icons/Home.svg");
    background-size: contain;
  }

  &--home--active {
    width: 18px;
    background-color: $bfs-blue;
    -webkit-mask-image: url("../icons/Home.svg");
    mask-image: url("../icons/Home.svg");
    background-size: contain;
  }

  &--logout {
    background-image: url("../icons/Uitloggen.svg");
    background-size: contain;
  }

  &--help {
    height: 20px;
    background-image: url("../icons/question.svg");
    background-size: contain;
  }

  &--help--active {
    height: 20px;
    background-size: contain;
    background-color: $bfs-blue;
    -webkit-mask-image: url("../icons/question.svg");
    mask-image: url("../icons/question.svg");
  }

  &--download {
    background-image: url("../icons/Download.svg");
  }
}

.bfs-large-icon {
  width: 30px;
  height: 30px;
}

.bfs-dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;

  &--red {
    background-color: #d10000;
  }

  &--green {
    background-color: #82c341;
  }

  &--grey {
    background-color: #969799;
  }
}

.bfs-arrow {
  border: solid $bfs-blue;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;

  &--right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &--left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &--up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  &--down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  &__wrapper {
    margin-left: 10px;
    display: inline-block;
  }
}
