.bfs-footer {
  display: flex;
  justify-content: center;
  margin: 30px 0;

  &__item {
    font-size: 12px;
    margin: 5px 20px;
    display: flex;
    flex-direction: row;
    color: $bfs-dark-grey;

    span {
      margin: 0 2px;
    }
  }

  &__header {
    @extend .bfs-footer__item;
    font-weight: bold;
  }
}
