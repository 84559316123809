.bfs-datatable {
  color: $bfs-grey;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;

  &.dataTable {
    &.no-footer {
      border-bottom: none;
    }

    thead {
      tr {
        background-color: #f4f4f4;
      }

      th {
        border-bottom: none;
        line-height: normal;
      }
    }

    &.display tbody {
      border-bottom: none;

      td {
        //padding: 2px 5px;
        border-top: 2px solid $bfs-light-grey;
        line-height: normal;
      }
    }
  }
}
//
//table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
//  //padding-right: 33px;
//}
//
//table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
//  //right: 0.5em;
//  content: "";
//}
//
//table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:before {
//  //right: 1em;
//  content: "";
//}
//
//table.dataTable thead th, table.dataTable tfoot th, table.dataTable.no-footer {
//  border-bottom: 0!important;
//}
//
//table.dataTable {
//  margin-top: 0px !important;
//  margin-bottom: 0px !important;
//}
//
//table.dataTable tbody td {
//  padding: 10px !important;
//}
//
//table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
//  padding-right: 17px;
//}
//
//table.dataTable thead th, table.dataTable thead td {
//  //padding: 15!important;
//}

//#newsTable {
//  .dateColumn {
//    width: 23%;
//  }
//
//  .subjectColumn {
//    width: 39%;
//
//  }
//  .actionColumn {
//    width: 38%;
//  }
//}
//
//#categoryListTable {
//  .nameColumn {
//    width: 47.5%;
//    text-align: left;
//    padding-left: 25px !important;
//  }
//
//  .onlineColumn {
//    width: 24%
//  }
//}
//
//#myTable, #myTable2 {
//  .min {
//    width: 11%;
//  }
//  .medium {
//    width: 15%;
//  }
//
//  .big {
//    width: 30%;
//  }
//  .before_medium {
//    width: 13%;
//  }
//}
