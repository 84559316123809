@charset "UTF-8";

// customize some Bootstrap variables
$primary: darken(#428bca, 20%);
$bfs-light-grey: #f8f8f8;
$bfs-blue: #0097d8;
$bfs-grey: #737373;
$bfs-dark-grey: #5f5f5f;
$bfs-white: #ffffff;
$bfs-yellow: #eeeb42;

$tender-color: #91C154;
$cluster-color: #0097D8;

// the ~ allows you to reference things in node_modules
@import "~bootstrap";
@import "bootstrap_customized";
@import "~datatables.net-src/css/jquery.dataTables";
@import "datatables_customized";

* {
  padding: 0;
  margin: 0;
}

body {
  background-color: $bfs-light-grey;
  // bootstrap has already used this font-family
  //font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-style: normal;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.bfs-logo {
  display: block;
  background-image: url("../images/logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100%;
  width: 100%;
}

label.required:after {
  content: "* ";
}

.bfs-button {
  background-color: $bfs-blue;
  border: none;
  border-radius: 40px;
  margin: 20px auto;
  height: 40px;
  padding: 5px 15px;
}

.bfs-btn-no-glow {
  background-color: $bfs-blue;
  border: none;
  border-radius: 40px;
  margin: 20px auto;
  height: 40px;
  padding: 5px 15px;
}

.bfs-back-button {
  border-radius: 40px;
  margin: 15px auto 20px;
  height: 40px;
  padding: 5px 15px;
  border: none;
  box-shadow: 0 0 0 rgba(0,151,216,0.5);
}

.alert-danger {
  position: fixed;
  top: 0; left: 0; bottom: 0; right: 0;
  margin: auto;
  margin-right: 5em;
  margin-top: 5em;
  max-width: 30em;
  max-height: 5em;
  z-index: 1;
}

.table-row-color:hover {
  background-color: #f8f8f8;
  cursor: pointer;
}

@import "registration";
@import "login";
@import "header";
@import "menu";
@import "page";
@import "footer";
@import "icon";
@import "detail";
@import "table";
@import "search_input";
@import "message";
