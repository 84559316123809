.bfs-header-registration {
  padding: 40px 10%;
  display: flex;
  flex-direction: row;
  background-color: $bfs-white;
  align-items: center;
  justify-content: flex-start;

  .bfs-header-logo-wrapper {
    height: 75px;
    width: 20%;

    .bfs-registration-logo {
      background-image: url("../images/logo.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

.bfs-register-page {
  display: flex;
  justify-content: center;
  flex-direction: row;

  &__block {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 20px auto;
    width: 50%;
    background-color: $bfs-white;
    text-align: left;
    font-size: 14px;
    color: $bfs-grey;

    input, textarea, select {
      background-color: $bfs-light-grey;
    }
  }

  &__header {
    color: $bfs-blue;
    font-weight: bold;
    font-size: 14px;
    margin: 10px 0;
  }

  &--botton-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;

    input {
      background-color: $bfs-blue;
      border: none;
      box-shadow: 0 0 20px rgba(0, 151, 216, 0.5);
      border-radius: 40px;
      width: 10%;
      margin: 20px auto;
      height: 50px;
    }
  }
}

.bfs-checkbox {
  label.required:after {
    content: " ";
  }
}

.g-recaptcha {
  margin: 0 auto;
}